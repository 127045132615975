import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { getHref } from '../../utils/navigation';
import trackEvent from '../../utils/tracking';
import NoUnderlineLink from '../../atoms/link-no-underline';
import Picture from '../../atoms/cms-picture';
import Typography from '../../atoms/typography';
import Ruler from '../../atoms/ruler';

const Wrapper = styled.section`
  padding: 0;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    transform: translateX(-6.5vw);
    width: calc(100% + 13vw);
    padding: 0 6.5vw;
  }
`;

const WrapperHeader = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.modularScale.large};

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    margin-bottom: ${(props) => props.theme.modularScale.xlarge};
  }
`;

const WrapperTitle = styled(Typography)`
  color: ${(props) => props.theme.color.brand};
  line-height: normal;

  a {
    text-decoration: none;
    font-family: inherit;
  }
`;

const ItemTitle = styled(Typography)`
  font-family: ${({ theme }) => theme.font.sans};
  text-align: center;
  line-height: ${({ theme }) => theme.modularScale.sixteen};
  margin-top: ${({ theme }) => theme.modularScale.sixteen};
  font-size: ${({ theme }) => theme.modularScale.thirteen};

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    font-size: ${({ theme }) => theme.modularScale.sixteen};
  }

  ${(props) => props.uppercase && css`
    text-transform: uppercase;
    letter-spacing: 0.24em;
  `}
`;

const ItemImage = styled(Picture)``;

const Item = styled.article`
  display: flex;
  max-width: 110px;
  place-self: stretch center;

  &:hover {
    ${ItemImage} {
      ${(props) => (props.wiggleOnHover ? css`
      animation-duration: 0.4s;
      animation-name: ${props.theme.animations.wiggle};
      ` : null)}
    }
  }
`;

const Items = styled('div', { shouldForwardProp: (prop) => prop !== 'itemLength' })`
  display: grid;
  grid-template-columns: repeat(${({ itemLength }) => Math.ceil(itemLength / 2)}, 1fr);
  grid-column-gap: ${({ theme }) => theme.modularScale.eight};
  grid-row-gap: ${({ theme }) => theme.modularScale.thirtyTwo};

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    grid-column-gap: ${({ theme }) => theme.modularScale.sixteen};
    align-items: center;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    grid-template-columns: repeat(${({ itemLength }) => itemLength}, 1fr);
    grid-column-gap: ${({ theme }) => theme.modularScale.eight};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.large}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const ItemSubtitle = styled(Typography)`
  color: ${(props) => props.theme.color.brand};
  margin-bottom: ${(props) => props.theme.modularScale.medium};
`;

const HomepageShopBy = (props) => {
  const items = props?.data?.elements;

  const contentCheck = () => props.data.heading
    || props.data.subheading
    || props.data.title_url;

  const handleTracking = () => trackEvent({
    eventAction: 'Homepage',
    eventCategory: 'Content Engagement',
    eventLabel: `Content Slot 7 (${props?.data?.heading ?? 'Category Taxon Shop'})`
  });

  return (
    <Wrapper
      onClick={handleTracking}
    >
      {
        contentCheck() && (
          <WrapperHeader>
            {
              props.data.heading
                ? (
                  <WrapperTitle element="h2" like="heading-4">
                    {props.data.title_url ? (
                      <Link href={props.data.title_url}>
                        <a>
                          {props.data.heading}
                        </a>
                      </Link>
                    ) : props.data.heading}
                  </WrapperTitle>
                )
                : null
            }
          </WrapperHeader>
        )
      }

      <Items itemLength={items?.length}>
        {
          items.map((item, index) => (
            /* eslint-disable react/no-array-index-key, max-len */
            <Item
              key={`homepage-shop-${index}`}
              wiggleOnHover
              paddingsOnMobile={props.paddingsOnMobile}
            >
              <NoUnderlineLink
                href={getHref({ navigation_url: item.path })}
                fullWidth
              >
                {
                  item?.icon
                  && (
                    <ItemImage
                      alt={item.icon_alt}
                      {...item.icon}
                      circle={!!item?.image_circle || item?.display_image_as_circle}
                      disableLazyload={props.disableLazyload}
                    />
                  )
                }

                {
                  item.heading
                  && (
                    <header>
                      {item?.sub_heading
                        && (
                          <ItemSubtitle
                            element={!props.data.subheading ? 'h3' : 'h4'}
                            like="dec-1"
                          >
                            {item.sub_heading}
                          </ItemSubtitle>
                        )}

                      <ItemTitle
                        element="h4"
                        like="label-1"
                      >
                        {item.heading}
                      </ItemTitle>
                    </header>
                  )
                }
              </NoUnderlineLink>
            </Item>
          ))
        }
      </Items>
      {props.data?.hr && <Ruler />}
    </Wrapper>
  );
};

HomepageShopBy.defaultProps = {
  paddingsOnMobile: false,
  disableLazyload: false
};

HomepageShopBy.propTypes = {
  data: PropTypes.object.isRequired,
  paddingsOnMobile: PropTypes.bool,
  disableLazyload: PropTypes.bool
};

HomepageShopBy.whyDidYouRender = true;

export default HomepageShopBy;
